import * as HighchartsTS from 'highcharts';

/**
 * Override Highcharts/Highstock modules
 * source : https://github.com/Microsoft/TypeScript/issues/10859
 */
declare module 'highcharts' {
  interface ChartObject {
    zoomOut(): void;
  }
  interface ChartEvents {
    render?(event: Event): void; // missing render event
  }
  interface NoDataOptions {
    useHTML?: boolean; // missing useHTML property
  }
  interface SeriesObject {
    remove(
      redraw?: boolean,
      animation?: boolean | HighchartsTS.Animation,
      withEvent?: boolean
    ): void;
  }
}
/**
 * Highstock module issues on StockChart
 * method => setOptions missing
 * method => 3 arguments signature method is missing
 * property  => bad definition in Options interface for responsive
 * interface/properties => Gradient interface is missing with get and setOpacity properties could be undefined
 */
declare module 'highcharts/highstock' {
  interface Static {
    setOptions(options: any): Options;
    mapChart(
      renderTo: string | HTMLElement,
      options: Options,
      callback?: (chart: ChartObject) => void
    ): ChartObject;
  }
  interface Chart {
    new (
      renderTo: string | HTMLElement,
      options: Options,
      callback?: (chart: ChartObject) => void
    ): ChartObject;
  }
  interface Gradient extends HighchartsTS.Gradient {
    get(format: string): string;
    setOpacity(alpha: number): Gradient;
  }
}

/**
 * String to Function
 * Source: http://stackoverflow.com/questions/7650071/is-there-a-way-to-create-a-function-from-a-string-with-javascript/26917938#26917938
 */
/* declare global {
  interface String {
    parseFunction(): any;
  }
}
if (typeof String.prototype.parseFunction !== 'function') {
  String.prototype.parseFunction = function(): any {
    const funcReg: RegExp = /function *\(([^()]*)\)[ \n\t]*{(.*)}/gim;
    const match: RegExpExecArray | null = funcReg.exec(
      this.replace(/\n/g, ' ')
    );
    if (match) {
      return new Function(...match[1].split(','), match[2]);
    }
    return null;
  };
} */

// source : https://basarat.gitbooks.io/typescript/content/docs/types/literal-types.html
/** Utility function to create a K:V from a list of strings */
function strEnum<T extends string>(o: Array<T>): { [K in T]: K } {
  return o.reduce((res, key) => {
    res[key] = key;
    return res;
  }, Object.create(null));
}
/** Create a K:V */
export const HIGHCHARTS_THEMES = strEnum(['standard', 'dark']);
/** Create a Type */
export type HIGHCHARTS_THEMES = keyof typeof HIGHCHARTS_THEMES;

export const HIGHCHARTS_THUMBNAILS = strEnum([
  'interactive',
  'none',
  'preview',
  'static'
]);
export type HIGHCHARTS_THUMBNAILS = keyof typeof HIGHCHARTS_THUMBNAILS;

export const HIGHCHARTS_TYPES = strEnum([
  'area',
  'arearange',
  'areaspline',
  'areasplinerange',
  'bar',
  'bellcurve',
  'boxplot',
  'bubble',
  'bullet',
  'column',
  'columnrange',
  'errorbar',
  'funnel',
  'gauge',
  'heatmap',
  'histogram',
  'line',
  'pareto',
  'map',
  'pie',
  'polygon',
  'pyramid',
  'sankey',
  'scatter',
  'scatter3d',
  'solidgauge',
  'spline',
  'streamgraph',
  'sunburst',
  'tilemap',
  'treemap',
  'variablepie',
  'variwide',
  'vector',
  'waterfall',
  'windbarb',
  'wordcloud',
  'xrange',
  // New types
  'areatrend',
  'barprogress',
  'columnprogress',
  'columnstacked',
  'combo',
  'donut',
  'polarclock',
  'stock',
  'variabledonut'
]);
export type HIGHCHARTS_TYPES = keyof typeof HIGHCHARTS_TYPES;

export interface IDependencyResponse extends HighchartsTS.Options {
  actionRequired?: boolean;
  message?: string;
}
