export default {
  chart: {
    style: {
      fontFamily: 'Inter, Roboto, "Source Sans Pro", Arial, sans-serif',
      fontSize: '14px',
      margin: 'auto'
    }
  },
  legend: {
    align: 'right',
    floating: true,
    itemStyle: { fontSize: '1em', fontWeight: 'normal' },
    layout: 'horizontal',
    verticalAlign: 'top'
  },
  navigator: {
    maskInside: false,
    series: { type: 'line' }
  },
  noData: {
    position: { align: 'left', verticalAlign: 'top', x: -3 },
    style: { fontSize: '14px', fontWeight: 'normal' },
    useHTML: true
  },
  plotOptions: {
    area: {
      lineWidth: 2,
      marker: {
        enabled: false,
        lineColor: undefined,
        lineWidth: 2,
        symbol: 'circle'
      },
      states: { hover: { lineWidth: 2 } },
      threshold: null
    },
    arearange: {
      lineWidth: 2,
      marker: {
        enabled: false,
        lineColor: undefined,
        lineWidth: 2,
        symbol: 'circle'
      },
      states: { hover: { lineWidth: 2 } },
      threshold: null
    },
    areaspline: {
      lineWidth: 2,
      marker: {
        enabled: false,
        lineColor: undefined,
        lineWidth: 2,
        symbol: 'circle'
      },
      states: { hover: { lineWidth: 2 } },
      threshold: null
    },
    areasplinerange: {
      lineWidth: 2,
      marker: {
        enabled: false,
        lineColor: undefined,
        lineWidth: 2,
        symbol: 'circle'
      },
      states: { hover: { lineWidth: 2 } },
      threshold: null
    },
    bubble: { marker: { enabled: true } },
    column: {
      pointPadding: 0.03,
      borderWidth: 0
    },
    line: {
      lineWidth: 2,
      marker: {
        enabled: false,
        lineColor: undefined,
        lineWidth: 2,
        symbol: 'circle'
      },
      states: { hover: { lineWidth: 2 } },
      threshold: null
    },
    map: {
      borderWidth: 0.5
    },
    pie: {
      borderWidth: 0,
      dataLabels: {
        distance: -35,
        enabled: false,
        style: { fontSize: '1em' }
      },
      showInLegend: true,
      slicedOffset: 10
    },
    series: {
      stickyTracking: false
    },
    solidgauge: {
      dataLabels: { enabled: false },
      linecap: 'round',
      stickyTracking: false,
      rounded: true
    },
    spline: {
      lineWidth: 2,
      marker: {
        enabled: false,
        lineColor: undefined,
        lineWidth: 2,
        symbol: 'circle'
      },
      states: { hover: { lineWidth: 2 } },
      threshold: null
    },
    scatter: { marker: { enabled: true } },
    variablepie: {
      borderWidth: 0,
      dataLabels: {
        distance: -35,
        enabled: false,
        style: { fontSize: '1em' }
      },
      showInLegend: true,
      slicedOffset: 10
    }
  },
  rangeSelector: { selected: 3, inputEnabled: false },
  responsive: {
    rules: [
      {
        condition: { maxWidth: 160 },
        chartOptions: { chart: { style: { fontSize: '9px' } } }
      },
      {
        condition: { maxWidth: 320 },
        chartOptions: { chart: { style: { fontSize: '11px' } } }
      }
    ]
  },
  subtitle: {
    align: 'left',
    style: { fontSize: '0.8571em' },
    text: undefined,
    useHTML: true
  },
  title: {
    align: 'left',
    style: { fontSize: '1.2857em', fontWeight: 'bold' },
    text: undefined,
    useHTML: true
  },
  tooltip: {
    hideDelay: 300,
    shared: true
  },
  xAxis: [
    { gridLineWidth: 1, labels: { style: { fontSize: '1.143em', fontWeight: 'bold' } }, tickWidth: 0 },
    { lineWidth: 0, tickWidth: 0 }
  ],
  yAxis: [
    {
      gridLineWidth: 0,
      labels: { align: 'right', style: { fontSize: '1em' }, x: -7, y: 14 },
      title: { text: undefined }
    },
    {
      gridLineWidth: 0,
      labels: { align: 'left', style: { fontSize: '1em' }, x: 7, y: 14 },
      opposite: true,
      title: { text: undefined }
    }
  ]
};
