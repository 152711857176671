import { WidgetConfiguration } from '@sgwt-widget/core/lib/config/WidgetConfiguration';
import { BUS_ACCESS_TOKEN, BUS_GRANTED_SCOPE } from './bus-topics';
import { registerEvent, SgwtWidgetName } from './monitoring';

// Functions required from @sgwt/connect-core library
export interface ISgwtConnectLibrary {
  discardAuthorization: () => void;
  discardAuthorizationLocally: () => void;
  getAuthHttpHeader?: () => string;         // Removed in v0.4.0
  getAuthorizationHeader?: () => string;    // Added in v0.4.0
  getGrantedScope?: () => string;           // Added in v0.6.0
  getIdTokenClaims?: () => string;          // Added in v0.9.0
  getJWTClaims?: () => string;              // Removed in v0.9.0
  on: (event: string, callback: () => void) => void;
  requestAuthorization: () => void;
}

/**
 * Get the SGWT Connect interface, either from <sgwt-connect> widget or from `window.sgwtConnect` variable.
 */
export function getSgwtConnectLibrary(): ISgwtConnectLibrary | undefined {
  // First, try to retrieve the <sgwt-connect> widget...
  const sgwtConnectWidget = document.querySelector('sgwt-connect');
  if (sgwtConnectWidget && (sgwtConnectWidget as any).sgwtConnect) {
    return (sgwtConnectWidget as any).sgwtConnect;
    // In case the widget is present in the page without sgwtConnect instance, we will check in the window properties.
    // This case should not really happen, since it means that the <sgwt-connect> widget is used in the passive mode,
    // and the instance has not been passed to it. But we never know...
  }
  // Now, find the sgwtConnect instance in window.
  return (window as any).sgwtConnect;
}

export function getInternationalizationLabels(
  i18n: any,
  additionalI18n?: any,
  language?: string
): any {
  const defaultI18n: any = { ...i18n.en };
  const currentLanguage: string = (language || 'EN').toLowerCase();
  if (
    i18n.hasOwnProperty(currentLanguage) &&
    additionalI18n.hasOwnProperty(currentLanguage)
  ) {
    return { ...i18n[currentLanguage], ...additionalI18n[currentLanguage] };
  } else if (additionalI18n.hasOwnProperty(currentLanguage)) {
    return { ...defaultI18n, ...additionalI18n[currentLanguage] };
  } else {
    if (i18n.hasOwnProperty(currentLanguage)) {
      return { ...i18n[currentLanguage] };
    } else {
      console.log(`No internationalization labels for "${currentLanguage}" (through "i18n" attribute).`);
      return defaultI18n;
    }
  }
}

/**
 * Check if the given object is null / undefined or empty (i.e. `{}`).
 */
export function emptyObject(obj?: object | null) {
  return !obj || Object.keys(obj).length === 0;
}

/**
 * Check the response status. If not 2xx, then throw an Error.
 */
export async function checkResponseStatus(response: Response): Promise<Response> {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    let errorMessage = response.statusText;
    try {
      const json = await response.json();
      errorMessage = `[${json.statusCode}] ${json.message}`;
    } catch (_e) {
    }
    throw new Error(errorMessage);
  }
}

export function parseResponseAsText(response: Response): Promise<string> {
  return response.text();
}

export function parseResponseAsJson(response: Response): Promise<object> {
  return response.json();
}

/**
 * Detect if displayed on an iOS device.
 * https://stackoverflow.com/questions/4460205/detect-ipad-iphone-webview-via-javascript
 */
export const isEmbeddedInIOS = (): boolean => {
  const standalone = (window.navigator as any).standalone;
  const userAgent = window.navigator.userAgent.toLowerCase();
  const safari = /safari/.test(userAgent); // check for webview & not browser
  const ios = /iphone|ipod|ipad/.test(userAgent);
  return ios && !standalone && !safari;
};

/**
 * The version in User Agent may be written "1.2.3" or "1_2_3". Normalize it to "1.2.3"...
 */
const normalizeSemVer = (version: string): string => {
  const convert = (x: string) => (parseInt(x, 10) || 0);
  const array = version.split(/[\._]/); // "1_2_3" -> ["1", "2", "3"]
  // "1.2.3" -> ["1", "2", "3"]
  return `${convert(array[0])}.${convert(array[1])}.${convert(array[2])}`;
};

/**
 * Check if the current browser is an Android browser in a webview. Not sure to work every time...
 * Resources:
 *   - https://developer.chrome.com/multidevice/user-agent#webview_user_agent
 *   - https://github.com/uupaa/UserAgent.js/blob/master/lib/UserAgent.js
 */
export const isEmbeddedInAndroid = (): boolean => {
  const ua = window.navigator.userAgent;
  const android = /Android/.test(ua);
  const chrome = /Chrome/.test(ua);
  if (android && chrome) {
    const version = normalizeSemVer(ua.split('Chrome/')[1].trim().split(/[^\w\.]/)[0]);
    if (parseFloat(version) >= 42) {
      return /;\swv/.test(ua);
    } else if (/\d{2}\.0\.0/.test(version)) {
      return true;
    }
    return (window as any).requestFileSystem || (window as any).webkitRequestFileSystem;
  }
  return false;
};

/**
 * Get the authorization token from sgwtConnect library or from bus...
 */
export function getAuthorizationToken(widgetConfiguration: WidgetConfiguration): string | undefined {
  // Get the sgwtConnect from the widget or from the window object...
  const sgwtConnect = getSgwtConnectLibrary();
  // Get the authorization token...
  const authorization: string | undefined = sgwtConnect
    ? (typeof sgwtConnect.getAuthorizationHeader === 'function'
      ? sgwtConnect.getAuthorizationHeader()
      : typeof sgwtConnect.getAuthHttpHeader === 'function' ?
        sgwtConnect.getAuthHttpHeader() : undefined
    )
    : widgetConfiguration.bus.dangerouslyGetCurrentValue<string | undefined>(BUS_ACCESS_TOKEN);
  return authorization;
}

/**
 * Add authentication information in the request header, depending on the authentication mode (sso-v1 or sg-connect-v2).
 */
export function addAuthenticationInfoInRequestHeader(
  options: any,
  widgetConfiguration: WidgetConfiguration,
  authentication?: string
) {
  if (authentication === 'sg-connect-v1' || authentication === 'sso-v1') {
    options.credentials = 'include'; // or 'same-origin'*/
  } else if (authentication === 'sg-connect-v2') {
    // For SG Connect v2 authentication,
    const authorization = getAuthorizationToken(widgetConfiguration);
    if (authorization) {
      options.headers.Authorization = authorization;
    } else {
      widgetConfiguration
        .log('[WARN] The widget is configured with the mode "sg-connect-v2" but the sgwtConnect library is not available on `window` ' +
          'and the Widget bus does not contains any information about the authentication...');
    }
  }
  return options;
}

/**
 * Check if the current session has a specific scope. We first try to get the granted scopes from the Widget bus
 * (normally populated by <sgwt-connect>), then by the `window.sgwtConnect` instance, if exists.
 * When the call returns `undefined`, it means that the list of granted scopes was not found.
 */
export function hasGrantedScope(widgetConfiguration: WidgetConfiguration, scope: string): boolean | undefined {
  let grantedScopes = widgetConfiguration.bus.dangerouslyGetCurrentValue<string | null>(BUS_GRANTED_SCOPE);
  if (grantedScopes === null || typeof grantedScopes === 'undefined') {
    const sgwtConnect = getSgwtConnectLibrary();
    if (typeof sgwtConnect !== 'undefined' && sgwtConnect.getGrantedScope) {
      grantedScopes = sgwtConnect.getGrantedScope();
    } else {
      widgetConfiguration.debug(`Could not define if the scope "${scope}" is granted as we are not able to find the list of granted scopes.`);
      return undefined;
    }
  }
  return grantedScopes !== null && typeof grantedScopes !== 'undefined' &&
    grantedScopes.trim().toLowerCase().split(/\s+/).some(s => s === scope.trim().toLowerCase());
}

/**
 * Get Claims from SGWTConnect. The function was `getJWTClaims()` until v.0.9.0 when it becomes `getIdTokenClaims()`.
 */
export const getClaimsFromSgwtConnect = (sgwtConnect: any | undefined): any | null => {
  if (sgwtConnect) {
    // For v0.9.0 or higher
    if (typeof sgwtConnect.getIdTokenClaims !== 'undefined') {
      return sgwtConnect.getIdTokenClaims();
    }
    // For v0.8.0 or lower
    if (typeof sgwtConnect.getJWTClaims !== 'undefined') {
      return sgwtConnect.getJWTClaims();
    }
  }
  return null;
};

/**
 * Check if the current environment is a production environment.
 */
export function isProductionEnvironment(widgetConfiguration: WidgetConfiguration) {
  const env = widgetConfiguration.environment;
  return !env || env.toLowerCase() === 'production';
}

/**
 * SSO v1 authentication is not supported anymore. We trace that information.
 */
export function warnSSOv1NotSupportedAnymore(widgetName: SgwtWidgetName) {
  console.log('*****************************************************************************************');
  console.error(`The SSO-v1 authentication system is not supported anymore for the widget ${widgetName}.`);
  console.log('*****************************************************************************************');
  registerEvent(widgetName, 'sso-v1-authentication');
}

/**
 * Check if the SG Markets mode is / should be enabled.
 */
export function modeSGMarketsEnabled(mode?: string): boolean {
  if (!!mode && mode.toLowerCase() === 'sg-markets') {
    return true;
  }
  const host = document.location.host.toLowerCase();
  return host.indexOf('.sgmarkets.com') > -1
    || host.indexOf('.sgmarkets-next.com') > -1
    || host.indexOf('.sgmarkets.world.socgen') > -1;
}

/**
 * Execute a function if the custom element is present in the page, or execute it when it becomes available.
 */
export function executeWhenCustomElementIsPresent(
  customElement: string,
  cb: () => void
) {
  // First check if we found the element in the DOM...
  if (document.querySelector(customElement) !== null) {
    return cb();
  } else if (window.customElements) {
    // ...or if it is defined in customElements...
    if (window.customElements.get(customElement)) {
      return cb();
    } else {
      // ...else, we wait in case it is defined later.
      window.customElements.whenDefined(customElement).then(cb);
    }
  }
}

/**
 * Copy the content of any node to the Clipboard.
 */
export function copyElementContentToClipboard(element?: HTMLElement) {
  if (element) {
    const range = document.createRange();
    range.selectNode(element);
    const selection = window.getSelection();
    if (selection !== null) {
      selection.removeAllRanges(); // clear current selection
      selection.addRange(range); // to select text
      document.execCommand('copy');
      selection.removeAllRanges(); // to deselect
    }
  }
}

/**
 * Sometimes it can be convenient to force the debug mode even if the `debug="true"` attribute in not set.
 * We can do that by setting a `sgwt-widgets.debug = true` in localStorage...
 */
export function isDebugForced() {
  const dbg = localStorage.getItem('sgwt-widgets.debug');
  return dbg !== null && dbg.toLowerCase() === 'true';
}
