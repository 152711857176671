export default {
  area: { chart: { type: 'area' } },
  arearange: { chart: { type: 'arearange' } },
  areaspline: { chart: { type: 'areaspline' } },
  areasplinerange: { chart: { type: 'areasplinerange' } },
  bar: {
    chart: { type: 'bar' },
    legend: { symbolRadius: 2 },
    xAxis: [
      { gridLineWidth: 0, labels: { style: { fontSize: '1em', fontWeight: 'normal' } } },
      { gridLineWidth: 0, labels: { style: { fontSize: '1em', fontWeight: 'normal' } } }
    ],
    yAxis: [
      { gridLineWidth: 1, labels: { style: { fontSize: '1.143em', fontWeight: 'bold' } } }
    ]
  },
  bellcurve: { chart: { type: 'bellcurve' } },
  boxplot: { chart: { type: 'boxplot' } },
  bubble: { chart: { type: 'bubble' } },
  bullet: { chart: { type: 'bullet' } },
  column: { chart: { type: 'column' }, legend: { symbolRadius: 2 } },
  columnrange: { chart: { type: 'columnrange' } },
  errorbar: { chart: { type: 'errorbar' } },
  funnel: { chart: { type: 'funnel' } },
  gauge: { chart: { type: 'gauge' } },
  heatmap: {
    chart: { type: 'heatmap' },
    legend: { floating: false, navigation: { enabled: false }, y: 20 }
  },
  histogram: { chart: { type: 'histogram' } },
  line: { chart: { type: 'line' } },
  map: {
    chart: { type: 'map' },
    legend: { floating: false, navigation: { enabled: false }, y: 20 },
    mapNavigation: { enabled: true, enableButtons: false },
    xAxis: [{ gridLineWidth: 0 }],
    yAxis: [{ labels: { enabled: false } }]
  },
  pareto: { chart: { type: 'pareto' } },
  pie: {
    chart: { type: 'pie' },
    legend: { align: 'left', layout: 'vertical' }
  },
  polygon: { chart: { type: 'polygon' } },
  pyramid: { chart: { type: 'pyramid' } },
  sankey: { chart: { type: 'sankey' } },
  scatter: {
    chart: { type: 'scatter', spacingBottom: 40 },
    legend: { align: 'center', verticalAlign: 'bottom' },
    xAxis: [{ labels: { enabled: false } }]
  },
  scatter3d: { chart: { type: 'scatter3d' } },
  solidgauge: { chart: { type: 'solidgauge' } },
  spline: { chart: { type: 'spline' } },
  streamgraph: { chart: { type: 'streamgraph' } },
  sunburst: { chart: { type: 'sunburst' } },
  tilemap: { chart: { type: 'tilemap' } },
  treemap: { chart: { type: 'treemap' } },
  variablepie: {
    chart: { type: 'variablepie' },
    legend: { align: 'left', layout: 'vertical' }
  },
  variwide: { chart: { type: 'variwide' } },
  vector: { chart: { type: 'vector' } },
  waterfall: { chart: { type: 'waterfall' } },
  windbarb: { chart: { type: 'windbarb' } },
  wordcloud: { chart: { type: 'wordcloud' } },
  xrange: { chart: { type: 'xrange' } },
  // New types
  areatrend: {
    chart: { type: 'area' },
    plotOptions: { area: { marker: { enabled: true } } }
  },
  barprogress: {
    chart: { type: 'bar', animation: false, showAxes: false },
    legend: { enabled: false },
    plotOptions: {
      bar: { grouping: false, pointWidth: 15 },
      series: { borderRadius: 8 }
    },
    tooltip: { formatter: function(this: any): string { return `${this.y}%`; } },
    xAxis: [
      { gridLineWidth: 0, labels: { align: 'left', enabled: true, x: 5, y: -15 }, lineWidth: 0 }
    ],
    yAxis: [{ labels: { enabled: false }, max: 100 }]
  },
  columnprogress: {
    chart: { type: 'column', animation: false, showAxes: false  },
    legend: { enabled: false },
    plotOptions: {
      column: { grouping: false, pointWidth: 15 },
      series: { borderRadius: 8 }
    },
    tooltip: { formatter: function(this: any): string { return `${this.y}%`; } },
    xAxis: [{ gridLineWidth: 0, lineWidth: 0 }],
    yAxis: [{ labels: { enabled: false }, max: 100 }]
  },
  columnstacked: {
    chart: { type: 'column' },
    legend: { symbolRadius: 2 },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: { enabled: true, formatter: function(this: any): string { return this.y > 0 ? this.y : null; } }
      }
    },
    yAxis: [{ reversedStacks: false }]
  },
  combo: {
    chart: { spacingBottom: 40 },
    legend: { align: 'center', symbolRadius: 2, verticalAlign: 'bottom' }
  },
  donut: {
    chart: { type: 'pie' },
    legend: { align: 'left', layout: 'vertical' },
    plotOptions: { pie: { dataLabels: { enabled: true, format: '{y}%' } } }
  },
  polarclock: {
    chart: { type: 'solidgauge' },
    legend: { enabled: false },
    tooltip: {
      borderWidth: 0,
      shadow: false,
      style: { fontSize: '1.143em' },
      pointFormat:
        '{series.name}<br><span style="font-size:2em; color: {point.color}; font-weight: bold">{point.y}%</span>',
      positioner: function(this: any, labelWidth: number): object {
        return {
          x: (this.chart.chartWidth - labelWidth) / 2,
          y: this.chart.chartHeight / 2 - 20
        };
      }
    },
    yAxis: [
      { min: 0, max: 100, lineWidth: 0, tickPositions: [] },
      { min: 0, max: 100, lineWidth: 0, tickPositions: [] }
    ]
  },
  stock: { yAxis: [{ opposite: false }, { opposite: false }] },
  variabledonut: {
    chart: { type: 'variablepie' },
    legend: { align: 'left', layout: 'vertical' },
    plotOptions: { variablepie: { dataLabels: { enabled: true, format: '{y}%' } } }
  }
};
