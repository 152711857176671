import { h } from 'preact';
import { TEXTMESSAGE_TYPES } from '../sgwt-text-message.types';

interface IMessageProps {
  message?: string;
  supportEmail?: string;
  type?: TEXTMESSAGE_TYPES;
}

export default function Message({
  message,
  supportEmail,
  type = TEXTMESSAGE_TYPES.disclaimer
}: IMessageProps) {
  return (
    <div>
      <span
        class={`${type!.toLowerCase() === TEXTMESSAGE_TYPES.disclaimer
          ? 'sgb-'
          : ''}text-${type!.toLowerCase()}`}
        dangerouslySetInnerHTML={{ __html: message! }}
      />
      {supportEmail && <p>Support: {supportEmail}</p>}
    </div>
  );
}
