import {
  h,
  initializeWidgetConfiguration,
  SgWidget,
  WidgetConfigurationProvider
} from '@sgwt-widget/core';
import * as HighchartsTS from 'highcharts';
import * as Highcharts from 'highcharts/highstock';
import { ComponentProps, emit, props } from 'skatejs';
import { TEXTMESSAGE_TYPES } from '../sgwt-text-message/sgwt-text-message.types';
import Message from '../sgwt-text-message/components/Message';
import Loader from './components/Loader';
import {
  HIGHCHARTS_THEMES,
  HIGHCHARTS_THUMBNAILS,
  HIGHCHARTS_TYPES,
  IDependencyResponse
} from './sgwt-highcharts.types';
import {
  _cloneDeep,
  _merge,
  booleanProps,
  cleanUserOptions,
  dataFormatter,
  difference,
  emitEvent,
  guid,
  isEmptyObject,
  serieFormatter,
  seriesFormatter,
  setInitialOptions,
  xAxisLabelsFormatter
} from './shared/utils';
import { SgwtWidgetName, startWidgetMonitoring } from '../common/monitoring';

/** Highcharts plugins */
require('highcharts/highcharts-3d')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/accessibility')(Highcharts);
require('highcharts/modules/bullet')(Highcharts);
require('highcharts/modules/drag-panes')(Highcharts);
require('highcharts/modules/drilldown')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/funnel')(Highcharts);
require('highcharts/modules/heatmap')(Highcharts);
require('highcharts/modules/histogram-bellcurve')(Highcharts);
require('highcharts/modules/map')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
require('highcharts/modules/pareto')(Highcharts);
require('highcharts/modules/sankey')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
require('highcharts/modules/streamgraph')(Highcharts);
require('highcharts/modules/sunburst')(Highcharts);
require('highcharts/modules/tilemap')(Highcharts);
require('highcharts/modules/treemap')(Highcharts);
require('highcharts/modules/variable-pie')(Highcharts);
require('highcharts/modules/variwide')(Highcharts);
require('highcharts/modules/vector')(Highcharts);
require('highcharts/modules/windbarb')(Highcharts);
require('highcharts/modules/wordcloud')(Highcharts);
require('highcharts/modules/xrange')(Highcharts);

/** Lodash helper */
const _kebabCase = require('lodash.kebabcase');

/** Highcharts configurations */
const themeOptions = require('./shared/ThemeOptions').default;
const thumbnailOptions = require('./shared/ThumbnailOptions').default;
const typeOptions = require('./shared/TypeOptions').default;

const REFLOW_INTERVAL = 500;

export interface ISgwtHighchartsProps {
  chartOptions?: HighchartsTS.Options;
  credits?: boolean;
  exporting?: boolean;
  highchartsOptions?: HighchartsTS.GlobalOptions;
  immediate?: boolean;
  inputData?: object;
  legend?: boolean;
  loading?: boolean;
  message?: JSX.Element;
  reflow?: boolean;
  subtitle?: string;
  title?: string;
  theme?: HIGHCHARTS_THEMES;
  thumbnail?: HIGHCHARTS_THUMBNAILS;
  type?: HIGHCHARTS_TYPES;
  widgetDependencyBaseUrl?: string;
  widgetDependencyInputData?: string;
  widgetDependencyName?: string;
  widgetDependencyProperties?: object;
  widgetDependencyTopic?: string;
  xaxisPreformatted?: boolean;
}

/** An open bar interface for save some properties from everywhere */
interface ISaveOptionsObject {
  [key: string]: any;
}

export class SgwtHighcharts extends SgWidget<ISgwtHighchartsProps> {
  public static is = 'sgwt-highcharts';

  public static props: ComponentProps<ISgwtHighchartsProps> = {
    chartOptions: {
      ...props.object,
      deserialize: (v: string | null): any => {
        let value: HighchartsTS.Options = {};
        try {
          value = JSON.parse(v || '{}');
        } catch (e1) {
          try {
            value = JSON.parse(decodeURIComponent(v || '{}'));
          } catch (e2) {
            value = {};
          }
        }
        return value;
      }
    },
    credits: booleanProps,
    exporting: booleanProps,
    highchartsOptions: {
      ...props.object,
      deserialize: (v: string | null): any => {
        let value: HighchartsTS.GlobalOptions = {};
        try {
          value = JSON.parse(v || '{}');
        } catch (e1) {
          try {
            value = JSON.parse(decodeURIComponent(v || '{}'));
          } catch (e2) {
            value = {};
          }
        }
        return value;
      }
    },
    immediate: booleanProps,
    inputData: props.object,
    legend: { ...booleanProps, default: true },
    loading: booleanProps,
    message: props.any,
    reflow: booleanProps,
    subtitle: props.string,
    title: props.string,
    theme: {
      ...props.any,
      default: HIGHCHARTS_THEMES.standard
    },
    thumbnail: {
      ...props.any,
      default: HIGHCHARTS_THUMBNAILS.none
    },
    type: { ...props.any, default: HIGHCHARTS_TYPES.line },
    widgetDependencyBaseUrl: props.string,
    widgetDependencyInputData: props.string,
    widgetDependencyName: props.string,
    widgetDependencyProperties: {
      ...props.object,
      deserialize: (v: string | null): any => {
        let value: HighchartsTS.Options = {};
        try {
          value = JSON.parse(v || '{}');
        } catch (e1) {
          try {
            value = JSON.parse(decodeURIComponent(v || '{}'));
          } catch (e2) {
            value = {};
          }
        }
        return value;
      }
    },
    widgetDependencyTopic: props.string,
    xaxisPreformatted: { ...booleanProps, default: true }
  };

  private _chart: HighchartsTS.ChartObject | null = null;
  private _defaultBusTopic = guid();
  private _initialChartOptions: HighchartsTS.Options = {};
  private _firstTime: boolean = true;
  private _reflowIntervalHandle: number = -1;
  private _refs: {
    el: HTMLElement | null;
    widget: HTMLElement | null;
  } = { el: null, widget: null };
  private _saveProps: string = '{}';
  private chartOptions: HighchartsTS.Options = {};
  private Highcharts: any = Highcharts;
  private highchartsOptions: HighchartsTS.GlobalOptions = {};
  private immediate: boolean = false;
  private subscription: any;
  public title: string = '';
  public credits: boolean = false;
  public exporting: boolean = false;
  public legend: boolean = true;
  public loading: boolean = false;
  public message: JSX.Element | null = null;
  public saveChartOptions: ISaveOptionsObject = {};
  public subtitle: string = '';
  public theme: HIGHCHARTS_THEMES = HIGHCHARTS_THEMES.standard;
  public thumbnail: HIGHCHARTS_THUMBNAILS = HIGHCHARTS_THUMBNAILS.none;
  public type: HIGHCHARTS_TYPES = HIGHCHARTS_TYPES.line;
  public xaxisPreformatted: boolean = true;

  constructor() {
    super();
    this.widgetConfiguration.log(`${SgwtHighcharts.is} constructed`);
  }

  private handleUpdate = (options: IDependencyResponse): void => {
    if (!isEmptyObject(options) && !this._firstTime && this._chart) {
      let doIt = false;
      if (options.message) {
        this.message = <Message
          message={options.message}
          type={options.actionRequired ? TEXTMESSAGE_TYPES.danger : TEXTMESSAGE_TYPES.disclaimer}
        />;
      } else {
        this.message = null;
      }
      if (typeof options.actionRequired !== undefined) {
        delete options.actionRequired;
        doIt = true;
      }
      if (typeof options.message !== undefined) {
        delete options.message;
        doIt = true;
      }
      const series = options.series;
      if (series) { // Extract series
        this.removeSeries();
        if (Array.isArray(series) && series.length !== 0) {
          series.forEach(serie => {
            this.addSeries(serie);
          });
        }
        delete options.series;
      }
      // @START TMP FIX - Force rerender for show the message
      if (doIt) {
        if (this.renderer) {
          this.renderer(this.renderRoot, () => this.render && this.render(this as any));
        }
        if (this.rendered) {
          this.rendered();
        }
      }
      // @END TMP FIX
      if (!isEmptyObject(options)) {
        this.update(options);
      }
    }
  };

  private topicSubscribe(): void {
    if ((this.props.widgetDependencyName && this.props.widgetDependencyBaseUrl) || this.props.widgetDependencyTopic) {
      if ((this.subscription && this.subscription.topicName !== (this.props.widgetDependencyTopic || this._defaultBusTopic)) || !this.subscription) {
        this.subscription = this.widgetConfiguration.bus.subscribe<string | HighchartsTS.Options>(
          this.props.widgetDependencyTopic || this._defaultBusTopic,
          (options): void => {
            if (options) {
              if (this.loading) {
                this.hideLoading();
              }
              this.handleUpdate(typeof options === 'string' ? JSON.parse(options) as HighchartsTS.Options : options);
            } else {
              if (!this.loading) {
                this.showLoading();
              }
            }
          }
        );
        emitEvent.call(this, 'subscribing', {
          topic: this.props.widgetDependencyTopic || this._defaultBusTopic
        });
      }
    }
  }

  private updateWidgetDependency(): void {
    if ((this.props.widgetDependencyName && this.props.widgetDependencyBaseUrl) || this.props.widgetDependencyTopic) {
      if (this.subscription && this.subscription.topicName !== (this.props.widgetDependencyTopic || this._defaultBusTopic)) {
        this.widgetConfiguration.bus.unsubscribe(this.subscription);
      }
    }
    if (this.props.widgetDependencyName && this.props.widgetDependencyBaseUrl) {
      const sgwtWidgetConfiguration: any = (window as any)
        .SGWTWidgetConfiguration;
      if (
        sgwtWidgetConfiguration.dependencies === undefined ||
        !Array.isArray(sgwtWidgetConfiguration.dependencies)
      ) {
        sgwtWidgetConfiguration.dependencies = [];
      }
      if (
        sgwtWidgetConfiguration.dependencies.indexOf(
          this.props.widgetDependencyName
        ) < 0 &&
        customElements.get(this.props.widgetDependencyName) === undefined
      ) {
        const currentScript: HTMLScriptElement | SVGScriptElement | null =
          document.currentScript;
        sgwtWidgetConfiguration.dependencies.push(
          this.props.widgetDependencyName
        ); // Add the current widget name
        const script: HTMLScriptElement = document.createElement('script'); // create the script tag
        script.onerror = (err: any) => {
          throw new URIError(
            `The script ${this.props.widgetDependencyBaseUrl}/${this.props.widgetDependencyName}.js is not accessible.`
          );
        };
        if (currentScript) {
          // insert the script tag into the DOM before the current script
          currentScript.parentNode!.insertBefore(script, currentScript);
        } else {
          // else at the end of the body tag
          document.body.appendChild(script);
        }
        script.src = `${this.props.widgetDependencyBaseUrl}${this.props.widgetDependencyBaseUrl[this.props.widgetDependencyBaseUrl.length - 1] !== '/' ? '/' : ''}${this.props.widgetDependencyName}.js`;
      }
    }
  }

  public connectedCallback(): void {
    super.connectedCallback();
    this.updateWidgetDependency();
  }

  public disconnectedCallback(): void {
    if (this.subscription) {
      this.widgetConfiguration.bus.unsubscribe(this.subscription);
    }
    this.destroy();
    super.disconnectedCallback();
  }

  /** Emit an event when the props are updated after the first time (with diff object) */
  public updating(prevProps: ISgwtHighchartsProps) {
    /** START --- SG|Dashboard fix for metadata format */
    if (this.props && this.props.highchartsOptions) {
      if (typeof this.props.highchartsOptions === 'string') {
        let o: HighchartsTS.Options | string = this.props.highchartsOptions;
        try {
          o = JSON.parse(o);
        } catch (e1) {
          try {
            o = JSON.parse(decodeURIComponent(o));
          } catch (e2) {
            o = {};
          }
        }
        this.highchartsOptions = o as HighchartsTS.Options;
      }
      if (this._chart && JSON.stringify(this.highchartsOptions) !== JSON.stringify((prevProps && prevProps.highchartsOptions) || {})) {
        this.createChart(); // Needed when globals options are updated
      }
    }
    if (this.props && this.props.chartOptions) {
      if (typeof this.props.chartOptions === 'string') {
        let o: HighchartsTS.Options | string = this.props.chartOptions;
        try {
          o = JSON.parse(o);
        } catch (e1) {
          try {
            o = JSON.parse(decodeURIComponent(o));
          } catch (e2) {
            o = {};
          }
        }
        this.chartOptions = o as HighchartsTS.Options;
      }
    }
    /** END --- SG|Dashboard fix for metadata format */
    if (
      JSON.stringify((this.props && this.props.inputData) || {}) !== this._saveProps &&
      this.props.widgetDependencyInputData &&
      this._refs.widget &&
      typeof (this._refs.widget as any).propsChangedCallback === 'function'
    ) {
      this.showLoading();
      this._saveProps = JSON.stringify(this.props.inputData);
      if (this.props.widgetDependencyInputData !== 'inputData') {
        const newNext = { ...this.props, [this.props.widgetDependencyInputData]: this.props.inputData };
        const newPrev = { ...prevProps, [this.props.widgetDependencyInputData]: prevProps && prevProps.inputData ? prevProps.inputData : {} };
        delete newNext.inputData;
        delete newPrev.inputData;
        (this._refs.widget as any).propsChangedCallback(newNext, newPrev);
      } else {
        (this._refs.widget as any).propsChangedCallback(this.props, prevProps);
      }
    }
    if (
      (this.props && this.props.widgetDependencyBaseUrl) !== (prevProps && prevProps.widgetDependencyBaseUrl) ||
      (this.props && this.props.widgetDependencyName) !== (prevProps && prevProps.widgetDependencyName) ||
      (this.props && this.props.widgetDependencyTopic) !== (prevProps && prevProps.widgetDependencyTopic)
    ) {
      this.updateWidgetDependency();
      this.topicSubscribe();
    }
    const diff = difference(this.props, prevProps);
    if (!isEmptyObject(diff)) {
      this._chart && this._chart.update(this._updateChartOptions());
      if (!this._firstTime) {
        emitEvent.call(this, 'highcharts-updated', diff);
      }
    }
  }

  public render({loading, message, theme, widgetDependencyBaseUrl, widgetDependencyName, widgetDependencyProperties, widgetDependencyTopic}: ISgwtHighchartsProps) {
    let widget: JSX.Element | null = null;
    if (widgetDependencyName && widgetDependencyBaseUrl) {
      widget = h(widgetDependencyName, {
        ref: (widget: HTMLElement): void => {
          this._refs.widget = widget;
        },
        topic: widgetDependencyTopic || this._defaultBusTopic,
        ...this._parseProperties(widgetDependencyProperties)
      });
    }
    return (
      <WidgetConfigurationProvider widgetConfiguration={this.widgetConfiguration}>
        <div
          class="sgwt-highcharts-wrapper"
          title=""
          style={{ height: '100%', position: 'relative', width: '100%' }}
        >
          <div
            ref={(el: HTMLElement): void => {
              this._refs.el = el;
            }}
            data-theme={theme}
            style={{ height: '100%', width: '100%' }}
          />
          {widget}
          {loading && <Loader theme={theme!} />}
          {message && <div style={{
            backgroundColor: theme && theme === HIGHCHARTS_THEMES.standard ? '#fff' : '#000',
            height: '100%',
            left: '0px',
            margin: '0px',
            overflow: 'hidden',
            paddingLeft: '11px',
            position: 'absolute',
            top: '0',
            right: '0'
          }}>
            {message}
          </div>}
        </div>
      </WidgetConfigurationProvider>
    );
  }

  /** TEST - SG Dashboard specific code */
  private _parseProperties = (widgetDependencyProperties?: object): any => {
    let props: any = widgetDependencyProperties || {};
    if (props && typeof props === 'string') {
      try {
        props = JSON.parse(props);
      } catch (e1) {
        try {
          props = JSON.parse(decodeURIComponent(props));
        } catch (e2) {}
      }
    }
    const newProps: any = {};
    Object.keys(props).forEach(key => {
      newProps[_kebabCase(key)] = props[key];
    });
    return newProps;
  };

  /** TEST - SG Dashboard specific code */
  private _sendFirstInputData = () => {
    (this._refs.widget as any).removeEventListener(`${this.props.widgetDependencyName}--ready`, this._sendFirstInputData);
    if (typeof (this._refs.widget as any).sgwtHighcharts !== 'undefined') {
      (this._refs.widget as any).sgwtHighcharts = this;
    }
    this.showLoading();
    this._saveProps = JSON.stringify(this.props.inputData);
    if (this.props.widgetDependencyInputData && this.props.widgetDependencyInputData !== 'inputData') {
      const props = { ...this.props, [this.props.widgetDependencyInputData]: this.props.inputData };
      delete props.inputData;
      (this._refs.widget as any).propsChangedCallback(props, JSON.parse(this._saveProps));
    } else {
      (this._refs.widget as any).propsChangedCallback(this.props, JSON.parse(this._saveProps));
    }
  };

  public rendered(): void {
    if (this._firstTime) {
      if (this._refs.widget && this.props.widgetDependencyName) {
        (this._refs.widget as any).addEventListener(`${this.props.widgetDependencyName}--ready`, this._sendFirstInputData);
      }
      if (this.immediate) {
        // the ready event has moved into the chart creation callback
        this.createChart();
      } else {
        this._firstTime = false;
        emitEvent.call(this, 'ready');
        this.topicSubscribe();
      }
      startWidgetMonitoring(SgwtWidgetName.HIGHCHARTS);
    }
  }

  /** Get the current chart object */
  public get chart(): HighchartsTS.ChartObject | null {
    return this._chart;
  }

  /** Get the renderer of the chart object */
  // In fact, this is an API for the SkateJS component that exposes the renderer() method of Highcharts.
  // Unfortunately, it collides with the renderer() method used by SkateJS (previously it was rendererCallback())...
  public get highchartsRenderer(): HighchartsTS.RendererObject | null {
    return this._chart ? this._chart.renderer : null;
  }

  /**
	 * Add a series to the chart after render time.
	 * @param  {SeriesOptions} options
	 * @param  {Boolean} [redraw=true]
	 * @param  {AnimationOptions} [animation]
	 * @return {SeriesObject}
   **/
  public addSeries = (
    options: HighchartsTS.SeriesOptions = {},
    redraw?: boolean,
    animation?: boolean | HighchartsTS.Animation
  ): HighchartsTS.SeriesObject | null => {
    if (this._chart) {
      const addOptions: HighchartsTS.SeriesOptions = this._serieFormatter(
        options
      );
      if (this.chartOptions.series && Array.isArray(this.chartOptions.series)) {
        this.chartOptions.series.push(addOptions);
      } else {
        this.chartOptions = _merge({}, this.chartOptions, {
          series: [addOptions]
        });
      }
      return this._chart.addSeries(addOptions, redraw, animation);
    } else {
      return null;
    }
  };

  /** Constructor for creating a new chart object */
  public createChart = (
    options?: HighchartsTS.Options,
    callback?: (chart?: HighchartsTS.ChartObject) => void
  ): void => {
    // WARNING!!! Use it like a singleton or not ???
    if (!isEmptyObject(this.highchartsOptions)) {
      Highcharts.setOptions(this.highchartsOptions);
    }
    // build the complete options object configuration
    this._initialChartOptions = setInitialOptions(this);
    if (options) {
      this.chartOptions = options;
    }
    const currentOptions: HighchartsTS.Options = this._updateChartOptions();
    // define the default callback function
    const currentCallback: (chart: HighchartsTS.ChartObject) => void = (
      chart: HighchartsTS.ChartObject
    ): void => {
      /*const MS_TIMEOUT: number = 3000;*/
      setTimeout(
        () => {
          if (this.immediate && this._firstTime) {
            this._firstTime = false;
            // if (this.loading) { this.loading = false; }
            emitEvent.call(this, 'ready');
            this.topicSubscribe();
          } else {
            if (typeof callback === 'function') {
              callback.call(this, chart);
            }
          }
          if (this.props.reflow) {
            window.clearInterval(this._reflowIntervalHandle);
            this._reflowIntervalHandle = window.setInterval(() => {
              if (this._chart && typeof this._chart.reflow === 'function') {
                this._chart.reflow();
              }
            }, REFLOW_INTERVAL);
          } else {
            setTimeout(() => {
              if (this._chart && typeof this._chart.reflow === 'function') {
                this._chart.reflow();
              }
            }, REFLOW_INTERVAL);
          }
        } /*, MS_TIMEOUT*/
      ); // @TODO remove delay - Simulate loading for test only
    };
    // create the new chart object
    if (this.type === HIGHCHARTS_TYPES.stock) {
      this._chart = new Highcharts.StockChart(this._refs.el!, currentOptions, currentCallback);
    } else if (this.type === HIGHCHARTS_TYPES.map) {
      this._chart = Highcharts.mapChart(this._refs.el!, currentOptions, currentCallback);
    } else {
      this._chart = new Highcharts.Chart(this._refs.el!, currentOptions, currentCallback);
    }
  };

  public destroy = (): void => {
    if (this.props.reflow) {
      window.clearInterval(this._reflowIntervalHandle);
    }
    setTimeout(() => {
      if (this._chart && !isEmptyObject(this._chart)) {
        this._chart.destroy();
      }
    });
  };

  /**
   * @param  {Number} [index]
   * @return {SeriesObject | Array<SeriesObject>}
   */
  public getSeries = (
    index?: number
  ): HighchartsTS.SeriesObject | HighchartsTS.SeriesObject[] | null => {
    return this._getSeries(index);
  };
  // @TODO if needed, split getSeries or add getSeriesByIndex and getSeriesByName helper methods

  public getThemeOptions = (): object => {
    return _merge(
      {},
      themeOptions[this.theme].common,
      themeOptions[this.theme].chart[this.type],
      themeOptions[this.theme].thumbnail[this.thumbnail]
    );
  };

  public hideLoading = (): void => {
    this.loading = false;
  };

  /**
   * Apply a new set of data to the series and optionally redraw it.
   * @param  {Number} index
   * @param  {Array<Number> | Array<Number, Number> | Array<SeriesDataOptions>} data
   * @param  {Boolean} [redraw=true]
   * @param  {AnimationOptions} [animation]
   * @param  {Boolean} [updatePoints=true]
   */
  public setData = (
    index: number,
    data: any,
    redraw?: boolean,
    animation?: boolean | HighchartsTS.Animation,
    updatePoints?: boolean
  ): void => {
    if (!Array.isArray(data)) {
      return;
    }
    const series:
      | HighchartsTS.SeriesObject
      | HighchartsTS.SeriesObject[]
      | null = this._getSeries(index);
    if (series && !Array.isArray(series)) {
      const newData: any = this._dataFormatter(data);
      if (this.chartOptions.series && Array.isArray(this.chartOptions.series)) {
        this.chartOptions.series[index].data = newData;
        // special case for Polar clock
        /*if (this.type === HIGHCHARTS_TYPES.polarclock) {
          const paneBackground: any = {
            outerRadius: (newData[0] as HighchartsTS.DataPoint).radius,
            innerRadius: (newData[0] as HighchartsTS.DataPoint).innerRadius,
            backgroundColor: themeOptions[this.theme].common.sg.progressBackgroundColor,
            borderWidth: 0
          };
          (this.chartOptions.pane!.background! as HighchartsTS.PaneBackground[])[index] = paneBackground;
        }*/
        series.setData(newData, redraw, animation, updatePoints);
      }
    }
  };

  public reflow = (): void => {
    if (this._chart) {
      this._chart.reflow();
    }
  };

  /**
	 * Remove a series and optionally redraw the chart.
	 * @param  {Number} [index]
	 * @param  {Boolean} [redraw=true]
	 * @param  {AnimationOptions} [animation]
	 * @param  {Boolean} [withEvent=true]
   */
  public removeSeries = (
    index?: number,
    redraw?: boolean,
    animation?: boolean | HighchartsTS.Animation,
    withEvent?: boolean
  ): HighchartsTS.SeriesObject | HighchartsTS.SeriesObject[] | null => {
    const series:
      | HighchartsTS.SeriesObject
      | HighchartsTS.SeriesObject[]
      | null = this._getSeries(index);
    if (series) {
      if (Array.isArray(series)) {
        this.chartOptions.series!.splice(0, this.chartOptions.series!.length);
        // special case for Polar clock
        /*if (this.type === HIGHCHARTS_TYPES.polarclock) {
          delete this.chartOptions.pane;
        }*/
        [...series].forEach((serie: HighchartsTS.SeriesObject): void => {
          serie.remove(redraw, animation, withEvent);
        });
      } else {
        if (
          this.chartOptions.series &&
          Array.isArray(this.chartOptions.series) &&
          this.chartOptions.series[index!]
        ) {
          this.chartOptions.series.splice(index!, 1);
          // special case for Polar clock
          /*if (this.type === HIGHCHARTS_TYPES.polarclock) {
            (this.chartOptions.pane!.background! as HighchartsTS.PaneBackground[]).splice(index!, 1);
          }*/
          series.remove(redraw, animation, withEvent);
        }
      }
    }
    return series;
  };

  public resize = (): void => {
    if (this._chart) {
      this._chart.setSize(
        this._refs.el!.offsetWidth,
        this._refs.el!.offsetHeight
      );
    }
  };

  public showLoading = (): void => {
    this.loading = true;
  };

  /**
   * A generic function to update any element of the chart.
   * @param  {Options} options
   * @param  {Boolean} [redraw=true]
   */
  public update = (options: HighchartsTS.Options, redraw?: boolean): void => {
    if (this._chart) {
      this.chartOptions = _merge({}, this.chartOptions, options);
      this._chart.update(this._updateChartOptions(), redraw);
      if (!this.props.reflow) {
        setTimeout(() => {
          if (this._chart && typeof this._chart.reflow === 'function') {
            this._chart.reflow();
          }
        }, REFLOW_INTERVAL);
      }
    }
  };

  /**
	 * Update the series with a new set of options.
   * @param  {Number} index
	 * @param  {SeriesOptions} options
	 * @param  {Boolean} [redraw=true]
   */
  public updateSeries = (
    index: number,
    options: HighchartsTS.SeriesOptions,
    redraw?: boolean
  ): void => {
    const series:
      | HighchartsTS.SeriesObject
      | HighchartsTS.SeriesObject[]
      | null = this._getSeries(index);
    if (series && !Array.isArray(series)) {
      const newOptions: HighchartsTS.SeriesOptions = this._serieFormatter(
        options,
        index
      );
      if (this.chartOptions.series && Array.isArray(this.chartOptions.series)) {
        this.chartOptions.series[index] = newOptions;
        // special case for Polar clock
        /*if (this.type === HIGHCHARTS_TYPES.polarclock) {
          const paneBackground: any = {
            outerRadius: (newOptions.data![0] as HighchartsTS.DataPoint).radius,
            innerRadius: (newOptions.data![0] as HighchartsTS.DataPoint).innerRadius,
            backgroundColor: themeOptions[this.theme].common.sg.progressBackgroundColor,
            borderWidth: 0
          };
          (this.chartOptions.pane!.background! as HighchartsTS.PaneBackground[])[index] = paneBackground;
        }*/
        series.update(newOptions, redraw);
      }
    }
  };

  public zoomOut = (): void => {
    if (this._chart) {
      this._chart.zoomOut();
    }
  };

  /** Helper */
  private _dataFormatter = (data: any): any => {
    return dataFormatter(data, this.type, this.thumbnail);
  };

  /** Helper */
  private _getSeries = (
    index?: number
  ): HighchartsTS.SeriesObject | HighchartsTS.SeriesObject[] | null => {
    if (!this.chart) {
      return null;
    }
    const series = this._chart!.series || [];
    if (series.length === 0) {
      this.widgetConfiguration.log('Chart is empty [no series]');
      return null;
    } else if (typeof index === 'undefined') {
      return series;
    } else if (index >= 0 && index < series.length) {
      return series[index];
    } else {
      this.widgetConfiguration.log(`Index '${index}' out of bounds`);
      return null;
    }
  };

  /** Helper */
  private _serieFormatter = (series: any, index?: number): any => {
    return serieFormatter(
      series,
      this.type,
      this.theme,
      this.thumbnail,
      index || (this._chart!.series || []).length
    );
  };

  /** Helper */
  private _seriesFormatter = (series: any): any => {
    return seriesFormatter(series, this.type, this.theme, this.thumbnail);
  };

  /** Helper for building the options object configuration */
  private _updateChartOptions = (): HighchartsTS.Options => {
    return _merge(
      {},
      this._initialChartOptions,
      themeOptions[this.theme].common,
      {
        credits: { enabled: this.credits },
        exporting: { enabled: this.exporting },
        lang: {
          noData: '<span class="sgb-text-disclaimer">No data available</span>'
        },
        legend: {
          enabled: this.legend,
          y: !this.title && !this.subtitle ? 0 : !this.subtitle ? 38 : 53
        },
        subtitle: { text: this.subtitle },
        title: { text: this.title },
        xAxis:
          this.xaxisPreformatted &&
          this.type !== HIGHCHARTS_TYPES.barprogress &&
          this.type !== HIGHCHARTS_TYPES.columnprogress
            ? [{ labels: { formatter: xAxisLabelsFormatter } }]
            : [{}]
      },
      typeOptions[this.type],
      themeOptions[this.theme].chart[this.type],
      thumbnailOptions[this.thumbnail],
      themeOptions[this.theme].thumbnail[this.thumbnail],
      this.thumbnail !== HIGHCHARTS_THUMBNAILS.none
        ? thumbnailOptions[this.type]
        : {},
      cleanUserOptions(this, this.chartOptions)
    );
  };

}

initializeWidgetConfiguration(SgwtHighcharts, {
  disablejQuerySanityCheck: true,
  neverUseShadowDOM: true
});
if (customElements.get(SgwtHighcharts.is) === undefined) {
  try {
    customElements.define(SgwtHighcharts.is, SgwtHighcharts);
  } catch (e) {}
}
