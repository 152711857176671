// source : https://basarat.gitbooks.io/typescript/content/docs/types/literal-types.html
/** Utility function to create a K:V from a list of strings */
function strEnum<T extends string>(o: Array<T>): { [K in T]: K } {
  return o.reduce((res, key) => {
    res[key] = key;
    return res;
  }, Object.create(null));
}

/** Create a K:V */
export const TEXTMESSAGE_TYPES = strEnum([
  'danger',
  'disclaimer',
  'info',
  'muted',
  'primary',
  'reverse',
  'success',
  'warning'
]);

/** Create a Type */
export type TEXTMESSAGE_TYPES = keyof typeof TEXTMESSAGE_TYPES;
