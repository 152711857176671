/// <reference path="../../../node_modules/@types/node/index.d.ts" />

import * as HighchartsTS from 'highcharts';
import * as Highcharts from 'highcharts/highstock';
import { emit, props } from 'skatejs';
import { SgwtHighcharts } from '../sgwt-highcharts';
import {
  HIGHCHARTS_THEMES,
  HIGHCHARTS_THUMBNAILS,
  HIGHCHARTS_TYPES
} from '../sgwt-highcharts.types';

/** Configuration */
const commonOptions = require('./CommonOptions').default;
const themeOptions = require('./ThemeOptions').default;

/** Lodash helpers */
export const _cloneDeep = require('lodash.clonedeep');
export const _isEqual = require('lodash.isequal');
export const _isObject = require('lodash.isobject');
export const _merge = require('lodash.merge');
export const _transform = require('lodash.transform');

/** Helper method for test if an object is empty */
export const isEmptyObject = (obj?: object | null): boolean => {
  return !obj || Object.keys(obj).length === 0;
};

/** Hack SkateJS boolean props (& any for function props) */
export const booleanProps: any = {
  ...props.boolean,
  coerce: (v: any): boolean =>
    v
      ? typeof v === 'boolean'
        ? v
        : typeof v === 'string' && v === 'true' ? true : false
      : false,
  deserialize: (v: string): boolean =>
    v ? (v === 'true' ? true : false) : false,
  serialize: (v: boolean): string => (v ? 'true' : 'false')
};
/* export const functionProps: any = {
  ...props.any,
  coerce: (v: any): any => v,
  deserialize: (v: string): any => (v ? v.parseFunction() : undefined),
  serialize: (v: any): string => (v ? String(v) : '')
}; */

export function emitEvent(
  this: SgwtHighcharts,
  name: string,
  detail?: object
): void {
  this.widgetConfiguration.log(
    `[skatejs][event] '${SgwtHighcharts.is}--${name}' - ${detail
      ? JSON.stringify(detail)
      : '{}'}`
  );
  emit(this, `${SgwtHighcharts.is}--${name}`, { detail: detail || {} });
}

export const cleanUserOptions = (
  self: SgwtHighcharts,
  options: HighchartsTS.Options
): HighchartsTS.Options => {
  const newOptions: HighchartsTS.Options = _cloneDeep(options);
  if (newOptions && !isEmptyObject(newOptions)) {
    let chartType: HIGHCHARTS_TYPES = self.type;
    // prevent chart properties to be overridden
    if (newOptions.chart) {
      // use events subscription
      // if (newOptions.chart.events) {
      //   delete newOptions.chart.events;
      // }
      // use type attribute/property
      if (
        newOptions.chart.type &&
        (HIGHCHARTS_TYPES as any)[newOptions.chart.type] &&
        (self.type as string) !== newOptions.chart.type
      ) {
        // delete newOptions.chart.type;
        chartType = newOptions.chart.type as HIGHCHARTS_TYPES;
      }
    }
    if (newOptions.xAxis && !Array.isArray(newOptions.xAxis)) {
      newOptions.xAxis = [newOptions.xAxis];
    }
    if (newOptions.yAxis && !Array.isArray(newOptions.yAxis)) {
      newOptions.yAxis = [newOptions.yAxis];
    }
    // use credits attribute/property
    if (
      newOptions.credits &&
      typeof newOptions.credits.enabled !== 'undefined'
    ) {
      // delete newOptions.credits.enabled;
    }
    // use exporting attribute/property
    if (
      newOptions.exporting &&
      typeof newOptions.exporting.enabled !== 'undefined'
    ) {
      // delete newOptions.exporting.enabled;
    }
    // use legend attribute/property
    if (newOptions.legend && typeof newOptions.legend.enabled !== 'undefined') {
      // delete newOptions.legend.enabled;
    }
    // use subtitle attribute/property
    if (
      newOptions.subtitle &&
      typeof newOptions.subtitle.text !== 'undefined'
    ) {
      // delete newOptions.subtitle.text;
    }
    // use title attribute/property
    if (newOptions.title && typeof newOptions.title.text !== 'undefined') {
      // delete newOptions.title.text;
    }
    // format series
    if (newOptions.series && Array.isArray(newOptions.series)) {
      newOptions.series = seriesFormatter(
        newOptions.series,
        // self.type,
        chartType,
        self.theme,
        self.thumbnail
      );
    }
  }
  return newOptions;
};

/** Set initial options object for Highcharts */
export const setInitialOptions = (
  self: SgwtHighcharts
): HighchartsTS.Options => {
  return _merge({}, commonOptions, {
    chart: {
      events: {
        // addSeries: function(event: any): boolean | void {
        //   // emitEvent.call(self, 'chart-series-added', { options: event.options });
        // },
        // click: function(event: any): void {
        //   // emitEvent.call(self, 'chart-click');
        // },
        // drilldown: function(event: any): void {
        //   // emitEvent.call(self, 'chart-drill-down');
        // },
        // drillup: function(event: any): void {
        //   // emitEvent.call(self, 'chart-drill-up');
        // },
        // drillupall: function(event: any): void {
        //   // emitEvent.call(self, 'chart-drill-up-all');
        // },
        load: function(this: any, event: any): void {
          this._initialChartOptions = _cloneDeep(this.options);
          // emitEvent.call(self, 'chart-load' /*, { options: self.options }*/);
        },
        // redraw: function(this: any, event: any): void {
        //   // emitEvent.call(self, 'chart-redraw');
        // },
        render: function(this: any, event: any): void {
          const update: HighchartsTS.Options = {};
          if (self.thumbnail === HIGHCHARTS_THUMBNAILS.static) {
            const panel = this.container.querySelector('rect.highcharts-thumbnail-panel');
            if (panel) {panel.remove(); }
            const plot = this.container.querySelector('g.highcharts-plot-bands-0');
            if (plot) {plot.remove(); }
            const rect = this.renderer
              .rect(0.5, this.chartHeight / 2, this.chartWidth - 1, this.chartHeight / 2 - 1, 0)
              .attr({
                'stroke-width': 0.5,
                stroke: self.theme === HIGHCHARTS_THEMES.dark ? '#777' : '#ccc',
                fill: self.theme === HIGHCHARTS_THEMES.dark ? 'rgba(128, 128, 128, 0.4)' : 'rgba(255, 255, 255, 0.8)',
                class: 'highcharts-thumbnail-panel'
              })
              .add()
              .toFront();
          }
          if (self.theme === HIGHCHARTS_THEMES.standard) {
            if (
              this.noDataLabel &&
              this.options.chart.plotBackgroundColor !== '#fff'
            ) {
              self.saveChartOptions.plotBackgroundColor = this.options.chart.plotBackgroundColor;
              update.chart = { plotBackgroundColor: '#fff' };
            } else if (
              !this.noDataLabel &&
              self.saveChartOptions.plotBackgroundColor
            ) {
              update.chart = {
                plotBackgroundColor: self.saveChartOptions.plotBackgroundColor
              };
              delete self.saveChartOptions.plotBackgroundColor;
            }
          }
          if (
            this.noDataLabel &&
            (typeof this.options.xAxis[0].visible === 'undefined' ||
              this.options.xAxis[0].visible ||
              typeof this.options.yAxis[0].visible === 'undefined' ||
              this.options.yAxis[0].visible)
          ) {
            update.xAxis = [{ visible: false }];
            // yAxis[>1] is possible too
            if (
              (this.options.yAxis.length > 1 &&
                typeof this.options.yAxis[1].visible === 'undefined') ||
              this.options.yAxis[1].visible
            ) {
              update.yAxis = [{ visible: false }, { visible: false }];
            } else {
              update.yAxis = [{ visible: false }];
            }
          } else if (
            !this.noDataLabel &&
            this._initialChartOptions &&
            (this.options.xAxis[0].visible !==
              this._initialChartOptions.xAxis[0].visible ||
              this.options.yAxis[0].visible !==
                this._initialChartOptions.yAxis[0].visible)
          ) {
            update.xAxis = [{
              visible: this._initialChartOptions.xAxis[0].visible
            }];
            // yAxis[>1] is possible too
            if (
              this.options.yAxis.length > 1 &&
              this.options.yAxis[1].visible !==
                this._initialChartOptions.yAxis[1].visible
            ) {
              update.yAxis = [
                { visible: this._initialChartOptions.yAxis[0].visible },
                { visible: this._initialChartOptions.yAxis[1].visible }
              ];
            } else {
              update.yAxis = [
                { visible: this._initialChartOptions.yAxis[0].visible }
              ];
            }
          }
          if (!isEmptyObject(update)) {
            this.update(update);
          }
          // emitEvent.call(self, 'chart-render');
        }// ,
        // selection: function(this: any, event: any): void {
          /*this.series.forEach((series: any): void => {
            series.points.forEach((point: HighchartsTS.PointObject): void => {
              if (
                event.xAxis &&
                point.x >= event.xAxis[0].min && point.x <= event.xAxis[0].max &&
                point.y >= event.yAxis[0].min && point.y <= event.yAxis[0].max
              ) {
                point.select(true, true);
              } else {
                point.select(false, true);
              }
            });
          });
          self._checkSelected();
          emitEvent.call(self, 'chart-selection', { selected: self.selected, nb: self.selectedPoints.length });
          // event.preventDefault();*/
          // emitEvent.call(self, 'chart-selection');
        // }
      }
    }
  }, self.type !== HIGHCHARTS_TYPES.map ? { zoomType: 'xy' } : {});
};

/** Helper: format labels styles for two lines - ex. month/year */
export const xAxisLabelsFormatter = function(this: any): string {
  const label: string = this.axis.defaultLabelFormatter.call(this);
  if (typeof label === 'string') {
    return label
      .split(/\s/)
      .map(function(_s: string, i: number): string {
        return i === 0
          ? _s
          : '<br /><span style="font-size: 1em;font-weight: normal;">' +
            _s +
            '</span>';
      })
      .join('');
  }
  return label;
};

export const dataFormatter = (
  data: any,
  type: HIGHCHARTS_TYPES,
  thumbnail: HIGHCHARTS_THUMBNAILS
): any => {
  if (!data) {
    return;
  }
  if (!Array.isArray(data)) {
    console.warn('[dataFormatter] - Unexpected data format');
  } else {
    const _last: number = data.length - 1;
    if (_last > -1) {
      if (
        (type === HIGHCHARTS_TYPES.line ||
          type === HIGHCHARTS_TYPES.spline ||
          type === HIGHCHARTS_TYPES.stock ||
          type === HIGHCHARTS_TYPES.area ||
          type === HIGHCHARTS_TYPES.arearange ||
          type === HIGHCHARTS_TYPES.areaspline ||
          type === HIGHCHARTS_TYPES.areasplinerange) &&
        thumbnail === HIGHCHARTS_THUMBNAILS.none
      ) {
        if (Array.isArray(data[_last]) && data[_last].length === 2) {
          data[_last] = {
            x: data[_last][0],
            y: data[_last][1]
          };
        } else if (typeof data[_last] !== 'object') {
          data[_last] = {
            y: data[_last]
          };
        }
        if (data[_last]) { data[_last].marker = { enabled: true }; }
      } else if (type === HIGHCHARTS_TYPES.bubble) {
        // Fix a bug introduced in v6.2.0 (fixed in v7.1.3). For Bubble chart, the "z" value should be set.
        // https://www.highcharts.com/blog/changelog/#highcharts-v7.1.3
        // https://github.com/highcharts/highcharts/issues/8608
        if (Array.isArray(data)) {
          data = data.map(val => {
            if (Array.isArray(val)) {
              return [...val];
            } else if (typeof val === 'number') {
              return { z: 1, y: val };
            } else if (typeof val === 'object' && !val.hasOwnProperty('z')) {
              return { z: 1, ...val };
            }
            return val;
          });
        }
      }
    }
  }
  return data;
};

/** Helper: format the serie has an object and add the data object attribute if needed */
const formatSerieForApplyStyles = (
  serie: HighchartsTS.SeriesOptions
): HighchartsTS.SeriesOptions => {
  if (serie && Array.isArray(serie)) {
    serie = { data: [...serie] };
  }
  return serie;
};

/** Helper: format serie with specifics styles and/or attributes */
export const serieFormatter = (
  s: HighchartsTS.SeriesOptions,
  type: HIGHCHARTS_TYPES,
  theme: HIGHCHARTS_THEMES,
  thumbnail: HIGHCHARTS_THUMBNAILS,
  index?: number
): HighchartsTS.SeriesOptions => {
  const serie: any = formatSerieForApplyStyles(s);
  if (!serie || typeof serie !== 'object') {
    console.warn('[serieFormatter] - Unexpected serie format');
  } else if (serie.data && Array.isArray(serie.data)) {
    const _type: HIGHCHARTS_TYPES = serie.type || type;
    if (
      typeof serie.color === 'undefined' &&
      typeof serie.colorIndex === 'undefined' &&
      typeof index !== 'undefined'
    ) {
      // Do not exceeds the number of defined colors, otherwise the series
      // will be displayed in transparent color!
      serie.colorIndex = index % themeOptions[theme].common.colors.length;
    }
    serie.data = dataFormatter(serie.data, _type, thumbnail);
    if (
      _type === HIGHCHARTS_TYPES.stock ||
      _type === HIGHCHARTS_TYPES.area ||
      _type === HIGHCHARTS_TYPES.arearange ||
      _type === HIGHCHARTS_TYPES.areaspline ||
      _type === HIGHCHARTS_TYPES.areasplinerange ||
      _type === HIGHCHARTS_TYPES.areatrend
    ) {
      const color: Highcharts.Gradient = Highcharts.Color(
        serie.colorIndex >= themeOptions[theme].common.colors.length ?
          '#b4bbcb' : themeOptions[theme].common.colors[serie.colorIndex]
      ) as Highcharts.Gradient;

      const startOpacity = serie.fillOpacity || 0.2;
      const endOpacity = serie.fillOpacity || 0;
      serie.fillColor = {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
        stops: [
          [0, color.setOpacity(startOpacity).get('rgba')],
          [1, color.setOpacity(endOpacity).get('rgba')]
        ]
      };
    } else if (type === HIGHCHARTS_TYPES.pie || type === HIGHCHARTS_TYPES.variablepie) {
      if (typeof serie.size === 'undefined') {
        serie.size = '100%';
      }
    } else if (type === HIGHCHARTS_TYPES.donut || type === HIGHCHARTS_TYPES.variabledonut) {
      if (typeof serie.size === 'undefined') {
        serie.size = '100%';
      }
      if (typeof serie.innerSize === 'undefined') {
        serie.innerSize = '60%';
      }
    }
  }
  return serie;
};

/** Helper: format series with specifics styles and/or attributes */
export const seriesFormatter = (
  se: HighchartsTS.SeriesOptions[],
  type: HIGHCHARTS_TYPES,
  theme: HIGHCHARTS_THEMES,
  thumbnail: HIGHCHARTS_THUMBNAILS
): HighchartsTS.SeriesOptions[] => {
  const series: HighchartsTS.SeriesOptions[] = [];
  if (!se || typeof se !== 'object' || !(se instanceof Array)) {
    console.warn('[seriesFormatter] - Unexpected series format');
  } else {
    se.forEach(function(s: HighchartsTS.SeriesOptions, i: number): void {
      const serie: HighchartsTS.SeriesOptions = serieFormatter(
        s,
        type,
        theme,
        thumbnail,
        i
      );
      if (
        (type === HIGHCHARTS_TYPES.barprogress ||
          type === HIGHCHARTS_TYPES.columnprogress) &&
        se.length === 1
      ) {
        if (
          serie &&
          typeof serie === 'object' &&
          serie.data &&
          Array.isArray(serie.data)
        ) {
          const newSerie: HighchartsTS.SeriesOptions = {
            color: themeOptions[theme].common.sg.progressBackgroundColor,
            data: [],
            enableMouseTracking: false,
            name: ''
          };
          serie.data.forEach(function(data: any): void {
            newSerie.data!.push(100);
          });
          series.push(newSerie);
        }
      }
      series.push(serie);
    });
  }
  return series;
};

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 * source: https://gist.github.com/Yimiprod/7ee176597fef230d1451
 */
interface IDifferenceObject {
  [key: string]: any;
}

export const difference = (
  object: IDifferenceObject = {},
  base: IDifferenceObject = {}
): IDifferenceObject => {
  const changes = (
    object: IDifferenceObject,
    base: IDifferenceObject
  ): IDifferenceObject => {
    return _transform(
      object,
      (result: IDifferenceObject, value: any, key: string): void => {
        if (!_isEqual(value, base[key])) {
          result[key] =
            _isObject(value) && _isObject(base[key])
              ? changes(value, base[key])
              : value;
        }
      }
    );
  };
  return changes(object, base);
};

export const guid = () => {
  const s4 = () => Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

