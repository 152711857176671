import { h } from 'preact';
import { HIGHCHARTS_THEMES } from '../sgwt-highcharts.types';

interface ILoaderProps {
  theme: HIGHCHARTS_THEMES;
}

export default function Loader({ theme }: ILoaderProps) {
  return (
    <div
      style={{
        backgroundColor: theme === HIGHCHARTS_THEMES.standard ? '#fff' : '#000',
        height: '100%',
        left: 0,
        margin: '0 auto',
        minHeight: '120px',
        opacity: 0.7,
        overflow: 'hidden',
        position: 'absolute',
        top: 0,
        width: '100%'
      }}
    >
      <h3
        style={{
          color: theme === HIGHCHARTS_THEMES.standard ? '#333' : '#ccc',
          left: '50%',
          position: 'absolute',
          top: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <i class="fa fa-spinner fa-pulse fa-2x fa-fw" />
        <span class="sr-only">Loading...</span>
      </h3>
    </div>
  );
}
