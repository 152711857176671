export default {
  interactive: {
    chart: {
      animation: false,
      height: 200,
      margin: [30, 5, 40, 5],
      width: 200
    },
    legend: {
      align: 'center',
      floating: false,
      itemDistance: 5,
      itemMarginTop: 4,
      itemStyle: { fontSize: '0.7em' },
      layout: 'horizontal',
      margin: 0,
      padding: 0,
      symbolPadding: 1,
      verticalAlign: 'bottom',
      y: 0
    },
    plotOptions: { pie: { dataLabels: { enabled: false } } },
    subtitle: {
      widthAdjust: 0
    },
    title: {
      widthAdjust: 0
    },
    xAxis: [
      { gridLineWidth: 0, labels: { enabled: false }, lineWidth: 0 }
    ],
    yAxis: [
      {
        gridLineWidth: 1,
        labels: {
          align: 'left',
          style: { fontSize: '0.7em' },
          x: 0,
          y: 10
        },
        title: {
          align: 'high',
          offset: 0,
          rotation: 0,
          style: { fontSize: '0.7em' },
          y: -5
        }
      },
      {
        gridLineWidth: 0,
        labels: {
          style: { fontSize: '0.7em' },
          x: 0,
          y: -10,
          align: 'right'
        },
        opposite: true,
        title: {
          align: 'high',
          offset: 0,
          rotation: 0,
          style: { fontSize: '0.7em' },
          y: -5
        }
      }
    ]
  },
  none: {},
  preview: {
    chart: {
      animation: false,
      height: 200,
      margin: [5, 5, 55, 5],
      showAxes: false,
      spacingTop: -20,
      width: 200
    },
    legend: { enabled: false },
    plotOptions: { pie: { dataLabels: { enabled: false } }, series: { enableMouseTracking: false } },
    subtitle: {
      align: 'left',
      style: {
        borderRadius: '2px',
        padding: '0 2px'
      },
      verticalAlign: 'bottom',
      widthAdjust: 0,
      y: 0
    },
    title: {
      verticalAlign: 'bottom',
      widthAdjust: 0,
      y: -20
    },
    tooltip: { enabled: false },
    xAxis: [
      { gridLineWidth: 0, lineWidth: 0, labels: { enabled: false } }
    ],
    yAxis: [
      { labels: { enabled: false } },
      { labels: { enabled: false } }
    ]
  },
  polarclock: {
    tooltip: {
      style: { fontSize: '0.5715em' },
      pointFormat:
        '{series.name}<br><span style="font-size:1em; color: {point.color}; font-weight: bold">{point.y}%</span>'
    }
  },
  static: {
    chart: {
      animation: false,
      height: 200,
      showAxes: false,
      width: 200
    },
    legend: { enabled: false },
    plotOptions: { pie: { dataLabels: { enabled: false } }, series: { enableMouseTracking: false } },
    subtitle: {
      align: 'left',
      style: {
        borderRadius: '2px',
        padding: '0 2px'
      },
      verticalAlign: 'bottom',
      widthAdjust: 0,
      y: -15
    },
    title: {
      verticalAlign: 'bottom',
      widthAdjust: 0,
      y: -60
    },
    tooltip: { enabled: false },
    xAxis: [
      { gridLineWidth: 0, lineWidth: 0, labels: { enabled: false } }
    ],
    yAxis: [
      { labels: { enabled: false } },
      { labels: { enabled: false } }
    ]
  }
};
