export default {
  standard: {
    common: {
      colors: [
        '#dc4941', // valencia
        '#444852', // mako
        '#b4bbcb', // heather
        '#6488ff', // cornflower-blue
        '#41c49d', // puerto-rico
        '#5c56e8', // royal-blue
        '#e88e5e', // apricot
        '#38699f', // azure
        '#408182', // faded-jade
        '#514b90', // victoria
        '#854b4b', // spicy-mix
        '#d53229', // mars
        '#e8e8e8', // mercury
        '#8b93a8', // manatee
        '#5d55ff', // dodger-blue
        '#68efad', // turquoise-blue
        '#00b4bb', // pacific-blue
        '#f6a416', // buttercup
        '#974a5a', // copper-rust
        '#4a5885', // east-bay
        '#275855', // plantation
        '#e60028', // sg red
        '#231f20', // sg black
        '#8a9496', // secondary
        '#d5dadb', // light
        '#434e54', // dark
        '#303333', // primary
        '#1361ff', // info
        '#02cc53', // success
        '#ff8b00', // warning
        '#d41a11' // danger
      ],
      chart: {
        backgroundColor: '#fff',
        plotBackgroundColor: '#ededed',
        style: { color: '#333' }
      },
      sg: { progressBackgroundColor: '#f5f5f5' },
      navigator: {
        maskFill: 'rgba(180, 180, 180, 0.3)',
        series: { color: '#dc4941' }
      },
      plotOptions: {
        area: {
          marker: { fillColor: '#fff' },
          states: { hover: { marker: { fillColor: '#fff' } } }
        },
        areaspline: {
          marker: { fillColor: '#fff' },
          states: { hover: { marker: { fillColor: '#fff' } } }
        },
        bubble: {
          marker: { fillColor: null }
        },
        line: {
          marker: { fillColor: '#fff' },
          states: { hover: { marker: { fillColor: '#fff' } } }
        },
        map: {
          borderColor: '#b4bbcb'
        },
        pie: {
          dataLabels: { style: { color: '#fff' } }
        },
        scatter: {
          marker: { fillColor: null }
        },
        spline: {
          marker: { fillColor: '#fff' },
          states: { hover: { marker: { fillColor: '#fff' } } }
        },
        variablepie: {
          dataLabels: { style: { color: '#fff' } }
        },
      },
      tooltip: {
        backgroundColor: '#2f333e',
        borderColor: '#2f333e',
        style: { color: '#fff' }
      },
      xAxis: [{ labels: { style: { color: '#333' } } }],
      yAxis: [
        { alternateGridColor: '#fff', labels: { style: { color: '#b4bbcb' } } },
        { labels: { style: { color: '#b4bbcb' } } }
      ]
    },
    chart: {
      bar: {
        chart: { plotBackgroundColor: null },
        xAxis: [{ labels: { style: { color: '#b4bbcb' } } }, { labels: { style: { color: '#b4bbcb' } } }],
        yAxis: [{ labels: { style: { color: '#333' } } }, { labels: { style: { color: '#333' } } }]
      },
      barprogress: {
        chart: { plotBackgroundColor: null }
      },
      columnprogress: {
        chart: { plotBackgroundColor: null }
      },
      combo: {
        chart: { plotBackgroundColor: null }
      },
      donut: {
        chart: { plotBackgroundColor: null }
      },
      heatmap: {
        colorAxis: { min: 0, minColor: '#fff', maxColor: '#dc4941' }
      },
      map: {
        chart: { plotBackgroundColor: null },
        colorAxis: { min: 0, stops: [[0, '#fff'], [1, '#dc4941']] }
      },
      pie: {
        chart: { plotBackgroundColor: null }
      },
      polarclock: {
        chart: { plotBackgroundColor: null },
        tooltip: { backgroundColor: 'none', style: { color: '#333' } }
      },
      variabledonut: {
        chart: { plotBackgroundColor: null }
      },
      variablepie: {
        chart: { plotBackgroundColor: null }
      }
    },
    thumbnail: {
      interactive: {
        chart: { borderColor: '#ccc', borderWidth: 0.5, plotBackgroundColor: null },
        yAxis: [
          { alternateGridColor: '#fff', labels: { style: { color: '#b4bbcb' } } },
          { labels: { style: { color: '#b4bbcb' } } }
        ]
      },
      none: {},
      preview: {
        chart: { borderColor: '#ccc', borderWidth: 0.5, plotBackgroundColor: null },
        subtitle: { style: { border: 'solid 1px #333' } }
      },
      static: {
        chart: { borderColor: '#ccc', borderWidth: 0.5, plotBackgroundColor: null },
        subtitle: { style: { border: 'solid 1px #333' } }
      }
    }
  },
  dark: {
    common: {
      colors: [
        '#d53229', // mars
        '#e8e8e8', // mercury
        '#8b93a8', // manatee
        '#5d55ff', // dodger-blue
        '#00b4bb', // pacific-blue
        '#68efad', // turquoise-blue
        '#f6a416', // buttercup
        '#974a5a', // copper-rust
        '#4a5885', // east-bay
        '#275855', // plantation
        '#dc4941', // valencia
        '#444852', // mako
        '#b4bbcb', // heather
        '#6488ff', // cornflower-blue
        '#41c49d', // puerto-rico
        '#5c56e8', // royal-blue
        '#e88e5e', // apricot
        '#38699f', // azure
        '#408182', // faded-jade
        '#514b90', // victoria
        '#854b4b', // spicy-mix
        '#e60028', // sg red
        '#231f20', // sg black
        '#8a9496', // secondary
        '#d5dadb', // light
        '#434e54', // dark
        '#ffffff', // white
        '#1361ff', // info
        '#02cc53', // success
        '#ff8b00', // warning
        '#d41a11' // danger
      ],
      chart: {
        backgroundColor: '#191e28',
        plotBackgroundColor: '#181c26',
        style: { color: '#ccc' }
      },
      sg: { progressBackgroundColor: '#0c111b' },
      legend: {
        itemHiddenStyle: { color: '#707070' },
        itemHoverStyle: { color: '#fff' },
        itemStyle: { color: '#777' }
      },
      navigator: {
        maskFill: 'rgba(180, 180, 180, 0.3)',
        series: { color: '#d53229' }
      },
      plotOptions: {
        area: {
          marker: { fillColor: '#181c26' },
          states: { hover: { marker: { fillColor: '#181c26' } } }
        },
        areaspline: {
          marker: { fillColor: '#181c26' },
          states: { hover: { marker: { fillColor: '#181c26' } } }
        },
        bar: { borderWidth: 0 },
        bubble: {
          marker: { fillColor: null }
        },
        line: {
          marker: { fillColor: '#181c26' },
          states: { hover: { marker: { fillColor: '#181c26' } } }
        },
        map: {
          borderColor: '#777'
        },
        pie: {
          borderColor: '#181c26',
          dataLabels: { style: { color: '#fff' } }
        },
        scatter: {
          marker: { fillColor: null }
        },
        spline: {
          marker: { fillColor: '#181c26' },
          states: { hover: { marker: { fillColor: '#181c26' } } }
        },
        variablepie: {
          borderColor: '#181c26',
          dataLabels: { style: { color: '#fff' } }
        }
      },
      subtitle: { style: { color: '#ccc' } },
      title: { style: { color: '#ccc' } },
      tooltip: {
        backgroundColor: '#2f333e',
        borderColor: '#2f333e',
        style: { color: '#fff' }
      },
      xAxis: [{ gridLineWidth: 0, labels: { style: { color: '#ccc' } }, lineColor: '#ccc' }],
      yAxis: [
        { alternateGridColor: '#21252f', labels: { style: { color: '#777' } } },
        { labels: { style: { color: '#777' } } }
      ]
    },
    chart: {
      bar: {
        chart: { plotBackgroundColor: null },
        xAxis: [{ labels: { style: { color: '#777' } } }, { labels: { style: { color: '#777' } } }],
        yAxis: [
          { gridLineColor: '#777', labels: { style: { color: '#ccc' } } },
          { gridLineColor: '#777', labels: { style: { color: '#ccc' } } }
        ]
      },
      barprogress: {
        chart: { plotBackgroundColor: null },
        yAxis: [{ alternateGridColor: '#191e28' }]
      },
      columnprogress: {
        chart: { plotBackgroundColor: null },
        yAxis: [{ alternateGridColor: '#191e28' }]
      },
      combo: {
        chart: { plotBackgroundColor: null }
      },
      donut: {
        chart: { plotBackgroundColor: null }
      },
      heatmap: {
        colorAxis: { min: 0, minColor: '#191e28', maxColor: '#d53229' }
      },
      map: {
        chart: { plotBackgroundColor: null },
        colorAxis: { min: 0, stops: [[0, '#191e28'], [1, '#d53229']] },
        yAxis: [{ alternateGridColor: '#191e28', }]
      },
      pie: {
        chart: { plotBackgroundColor: null }
      },
      polarclock: {
        chart: { plotBackgroundColor: null },
        tooltip: { backgroundColor: 'none', style: { color: '#fff' } }
      },
      variabledonut: {
        chart: { plotBackgroundColor: null }
      },
      variablepie: {
        chart: { plotBackgroundColor: null }
      }

    },
    thumbnail: {
      interactive: {
        chart: { borderColor: '#777', borderWidth: 0.5, plotBackgroundColor: null },
        yAxis: [
          { alternateGridColor: '#21252f', labels: { style: { color: '#777' } } },
          { labels: { style: { color: '#777' } } }
        ]
      },
      none: {},
      preview: {
        chart: { borderColor: '#777', borderWidth: 0.5, plotBackgroundColor: null },
        subtitle: { style: { border: 'solid 1px #777' } }
      },
      static: {
        chart: { borderColor: '#777', borderWidth: 0.5, plotBackgroundColor: null },
        subtitle: { style: { border: 'solid 1px #777' } }
      }
    }
  }
};
